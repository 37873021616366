<template>
    <div class="container">
        <section class="brand">
            <div class="welcome">
                <img class="logo" src="@/assets/logo.svg" alt="brand" />
                <p>Beyond the Records. Below the Surface Web.</p>
                <p>There is a lot to Discover.</p>
            </div>
            <div id="bg" :style="{backgroundImage: 'url(' + require('@/assets/auth-bg.svg') + ')'}"></div>
        </section>
        <section class="auth">
            <template>
                <div class="tw-w-full form-cnt tw-min-h-full">
                    <form @submit.prevent="submitForm" class="">
                        <div class="tw-flex tw-flex-col tw-gap-8 tw-pr-0 tw-pl-8 tw-ml-4">
                            <div>
                                <!-- <Logo1/> -->
                                <img :src="Logo1" class="logo" />
                            </div>
                            <div>
                                <p class="tw-block tw-mb-2 tw-text-black tw-text-2xl tw-font-semibold">Login to your account</p>
                                <p class="tw-block mb-2 tw-text-black tw-text-sm">
                                    Hi {{ activeUserName }}! We have shared a One Time Password to this email <b>{{ activeUserEmail }}</b>
                                </p>
                            </div>
                            <div class="tw-flex tw-flex-col tw-gap-4">
                                <div>
                                    <p class="tw-block tw-text-black tw-text-base">OTP</p>
                                </div>
                                <div class="otp-input tw-relative">
                                    <div class="otp-box-group" ref="otp-cnt">
                                        <input v-for="(item, idx) in formData.otp" inputmode="numeric" class="otp-box" :class="showError ? 'invalid-field' : 'tw-border-gray-300'" maxlength="1" v-model="formData.otp[idx]" @input="handleOtpChange" :key="idx" />
                                    </div>
                                    <span :class="{hideError: !showError}" class="tw-text-red-600 tw-text-xs">OTP Doesn't match</span>
                                </div>
                                <div>
                                    <p class="tw-block tw-mb-2 tw-text-black tw-text-sm">
                                        Didn’t Received your OTP?
                                        <span class="tw-text-primary tw-cursor-pointer resend-otp" @click="handleResend">Resend</span>
                                    </p>
                                </div>
                            </div>
                            <button class="tw-bg-blue-500 tw-hover:bg-blue-700 tw-text-white tw-font-bold tw-py-2 tw-px-4 tw-rounded tw-focus:outline-none tw-focus:shadow-outline tw-w-full tw-h-12 tw-cursor-pointer tw-mt-6" :class="{btnDisable: !isFormFilled || disabled}" type="submit" :disabled="!isFormFilled || disabled">
                                <nLoader v-if="disabled" class="tw-h-8 tw-w-8"></nLoader>
                                {{ !disabled ? "Login" : "" }}
                            </button>
                        </div>
                    </form>
                </div>
            </template>
        </section>
    </div>
</template>
<script>
// import  LoginImg from "@/assets/svg/loginScreen.svg";
// import  Eye from "@/assets/svg/eye.svg";
import VueCookies from "vue-cookies";
import {decodeToken} from "@/utils/auth";
import {LoginImg, Logo1} from "@/plugins/common.js";
import {verifyOtp, resendOtp} from "./services";
import sections from "./store";
import NLoader from "@/components/n-loader";

export default {
    components: {
        // Eye
        NLoader,
    },
    data() {
        return {
            LoginImg,
            Logo1,
            disabled: false,
            requestId: null,
            currTime: null,
            formData: {
                otp: ["", "", "", "", "", ""],
            },
            showError: false,
        };
    },
    mounted() {
        this.otpField();
    },
    methods: {
        otpField() {
            const inputs = this.$refs["otp-cnt"];

            inputs.addEventListener("input", function(e) {
                const target = e.target;
                const val = target.value;
                if (isNaN(val) || val == " ") {
                    target.value = "";
                    return;
                }

                if (val != "") {
                    const next = target.nextElementSibling;
                    if (next) {
                        next.focus();
                    }
                }
            });

            inputs.addEventListener("keyup", function(e) {
                const target = e.target;
                const key = e.key.toLowerCase();

                if (key == "backspace" || key == "delete") {
                    target.value = "";
                    const prev = target.previousElementSibling;
                    if (prev) {
                        prev.focus();
                    }
                    return;
                }
            });

            inputs.addEventListener("paste", this.handlePaste);
        },
        handleOtpChange() {
            this.showError = false;
        },
        async submitForm() {
            try {
                this.disabled = true;
                const payload = {
                    challenge_metadata: this.$route.query.requestId,
                    challenge_response: this.formData.otp.join(""),
                };
                const data = await verifyOtp(payload);
                this.disabled = false;
                if (data.status == false) {
                    if (data.status_code == 1005 || data.status_code == 1006) {
                        this.$toast.error(data.message);
                        this.$router.push("/");
                        return;
                    }
                    this.showError = true;
                    this.$toast.error(data.message || "Something went wrong!");
                    return;
                } else {
                    VueCookies.set("token", data.token);
                    let decodedToken = decodeToken(data.token);
                    let data1 = {decodedToken: decodedToken, token: data.token};
                    this.$store.dispatch("setLoginSession", data1);
                    this.moveToPath();
                }
            } catch (error) {
                this.disabled = false;
                this.$toast.error("Something went wrong!");
            }
        },
        moveToPath() {
            this.$router.push("/case-list");
        },
        async handleResend() {
            try {
                if (this.currTime) {
                    const timeDiff = new Date().getTime() - this.currTime;
                    if (timeDiff < 30000) {
                        this.$toast.error("Please wait for a total of 30 secs before resending the OTP!");
                        return;
                    }
                }
                this.currTime = new Date().getTime();
                this.formData.otp = ["", "", "", "", "", ""];
                this.showError = false;
                const requestId = this.$route.query.requestId;
                const data = await resendOtp({challenge_metadata: requestId});
                if (data.status == true) {
                    this.$toast.success("OTP resend successfully.");
                } else {
                    if (data.status_code == 1005 || data.status_code == 1006) {
                        this.$toast.error(data.message);
                        this.$router.push("/");
                        return;
                    }
                    this.$toast.error(data.message);
                }
            } catch (error) {
                this.$toast.success("Something went wrong!");
            }
        },
        handlePaste() {
            let login_otp = event.clipboardData.getData("text/plain").replace(/\s/g, "");
            this.formData.otp = login_otp.split("");
            this.formData.otp.length = 6;
            const inputs = document.querySelectorAll(".otp-box");
            const otp = this.formData.otp;
            for (let idx in otp) {
                inputs[idx].value = otp[idx];
                inputs[idx].focus();
            }
        },
    },
    computed: {
        isFormFilled() {
            return this.formData?.otp.join("").length == 6;
        },
        fillRequestId() {
            return this.$route.query;
        },
        activeUserEmail() {
            return sections.user_email;
        },
        activeUserName() {
            return sections.user_name;
        },
        isOtpEmpty() {
            return this.formData.otp.every((e) => e == "");
        },
    },
};
</script>
<style lang="scss">
.container {
    background-color: white;
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    height: 100vh;
    width: 100vw;

    .brand {
        height: 100%;
        background-color: var(--brand-color);
        display: grid;
        place-content: center;
        position: relative;

        .welcome {
            display: flex;
            flex-direction: column;
            align-items: center;
            position: relative;
            z-index: 999;

            #logo {
                width: 200px;
                height: 72px;
                margin-bottom: 5rem;
            }

            p {
                font-size: 32px;
                font-family: "Montserrat";
                color: white;
                margin: 0.5rem 2rem;
                font-weight: 200;
            }
        }

        #bg {
            position: absolute;
            height: 100%;
            width: 100%;
            background-repeat: no-repeat;
            background-size: cover;
        }
    }
    div,
    input {
        /* border: 1px solid red; */
    }

    .loginImage-cnt {
        width: 50%;
        height: 100vh;
        // overflow: hidden;
        display: flex;
    }
    .form-cnt {
        display: flex;
        flex-direction: column;
        justify-content: center;
        padding: 6%;
        background-color: white;
    }
    .logo {
        width: 215px;
        height: 72px;
    }
    .otp-input {
        position: relative;
        width: fit-content;
        /* overflow: hidden */
    }

    .otp-box {
        border-radius: 10px;
        border: 1px solid rgba(170, 175, 221, 0.42);
        background: rgba(245, 245, 245, 0.4);
        width: 50px;
        height: 50px;
        padding: 12px 10px 12px 18px;
    }

    .otp-box:not(:last-child) {
        margin-right: 10px;
    }

    .otp-box:focus {
        border: 1px solid #3b82f6;
    }

    .btnDisable {
        background-color: #ddd;
        color: #858585;
    }
    form {
        max-width: 35rem;
    }
    .rounded {
        border-radius: 10px;
    }
    .invalid-field {
        border: 1px solid red !important;
    }
    input {
        font-size: 16px;
        border-radius: 10px;
        border: 1px solid #d8dbe0;
    }
    input:focus {
        outline: 1px solid rgb(59, 130, 246);
    }
    input::placeholder {
        color: #49454f;
    }
    button {
        border-radius: 10px;
        border-width: 0px;
        font-size: 16px;
    }
    .resend-otp {
        color: rgb(13, 106, 213);
    }
    .auth {
        overflow-y: scroll;
    }
    * {
        font-family: "Poppins", sans-serif !important;
    }
    .hideError {
        visibility: hidden;
    }
    .error {
        position: absolute;
    }
}
</style>
