<template>
    <div class="container">
        <section class="brand">
            <div class="welcome">
                <img class="logo" src="@/assets/logo.svg" alt="brand" />
                <p>Beyond the Records. Below the Surface Web.</p>
                <p>There is a lot to Discover.</p>
            </div>
            <div id="bg" :style="{backgroundImage: 'url(' + require('@/assets/auth-bg.svg') + ')'}"></div>
        </section>
        <section class="auth">
            <template>
                <div class="tw-w-full form-cnt tw-min-h-full">
                    <form @submit.prevent="submitForm" class="tw-pr-10">
                        <div class="tw-flex tw-flex-col tw-gap-8 tw-pr-0 tw-pl-8 tw-ml-4">
                            <div>
                                <!-- <Logo1/> -->
                                <img :src="Logo1" class="logo" />
                            </div>
                            <div>
                                <p class="tw-block tw-mb-2 tw-text-black tw-text-2xl tw-font-semibold">{{ currentStep === 1 ? "Verify your Email ID" : "Enter Temporary Password" }}</p>
                                <p v-if="currentStep === 2" class="tw-block tw-mb-2 tw-text-black tw-text-sm">Hi {{ activeUserName }}! We have shared a temporary Password to this email {{ activeUserEmail }}</p>
                            </div>
                            <div>
                                <div v-if="currentStep === 1" class="tw-mb-8 tw-relative">
                                    <label for="Email" class="tw-block tw-mb-2 tw-text-sm tw-font-noraml tw-text-gray-900 tw-dark:text-white">Email</label>
                                    <div class="tw-flex tw-items-center tw-relative">
                                        <input
                                            type="text"
                                            id="Email"
                                            v-model="formData.email"
                                            class="tw-bg-gray-50 tw-border tw-border-gray-300 tw-text-gray-900 tw-text-base tw-rounded tw-focus:ring-blue-500 tw-focus:border-blue-500 tw-block tw-w-full tw-px-4 tw-py-2.5 tw-dark:bg-gray-700 tw-dark:border-gray-600 tw-dark:placeholder-gray-400 tw-dark:text-white tw-dark:focus:ring-blue-500 tw-dark:focus:border-blue-500 tw-h-12"
                                            :class="showError && !isEmailValid ? 'errorColor' : 'tw-border-gray-300'"
                                            placeholder="Enter Email"
                                        />
                                    </div>
                                    <span :class="{hideError: !(showError && !isEmailValid)}" class="tw-text-red-600 tw-text-xs error">Invalid Email</span>
                                </div>
                                <div class="tw-my-8 tw-relative" v-else>
                                    <label for="Temparary_password" class="tw-block tw-mb-2 tw-text-sm tw-font-noraml tw-text-gray-900 tw-dark:text-white">Temporary Password</label>
                                    <div class="tw-flex tw-items-center tw-relative">
                                        <input
                                            type="password"
                                            ref="passRef"
                                            id="Temparary_password"
                                            v-model="formData.password"
                                            autocomplete="new-password"
                                            class="tw-bg-gray-50 tw-border tw-border-gray-300 tw-text-gray-900 tw-text-base tw-rounded tw-focus:ring-blue-500 tw-focus:border-blue-500 tw-block tw-w-full tw-px-4 tw-py-2.5 tw-dark:bg-gray-700 tw-dark:border-gray-600 tw-dark:placeholder-gray-400 tw-dark:text-white tw-dark:focus:ring-blue-500 tw-dark:focus:border-blue-500 tw-h-12"
                                            :class="(showError && !isPassValid) || isTempPassInvalid ? 'errorColor' : 'tw-border-gray-300'"
                                            placeholder="Enter Temporary Password"
                                            @blur="handleInputBlur"
                                            @input="handleChange"
                                        />
                                        <img :src="closedEye" class="tw-absolute tw-right-4 tw-cursor-pointer" @click="togglePass" v-if="!isPassVisible" />
                                        <img :src="eye" class="tw-absolute tw-right-4 tw-cursor-pointer" @click="togglePass" v-else />
                                    </div>
                                    <span v-if="!isPassValid" :class="{hideError: !(showError && !isPassValid && formData.password)}" class="tw-text-red-600 tw-text-xs error">Should be a minimum of 8 characters</span>
                                    <span v-else :class="{hideError: !isTempPassInvalid}" class="tw-text-red-600 tw-text-xs error">Invalid password</span>
                                </div>
                            </div>
                            <button class="tw-bg-blue-500 tw-hover:bg-blue-700 tw-text-white ftw-ont-bold tw-py-2 tw-px-4 tw-rounded tw-focus:outline-none tw-focus:shadow-outline tw-w-full tw-h-12 tw-cursor-pointer" :class="{btnDisable: !isFormFilled || disabled}" type="submit" :disabled="!isFormFilled || disabled">
                                <nLoader v-if="disabled" class="tw-h-8 tw-w-8"></nLoader>
                                {{ !disabled ? "Continue" : "" }}
                            </button>
                        </div>
                    </form>
                </div>
            </template>
        </section>
    </div>
</template>
<script>
import {LoginImg, Logo1} from "@/plugins/common.js";
import {setTempPass, verifyTempPass} from "./services";
import sections from "./store";
import NLoader from "@/components/n-loader";

export default {
    components: {
        NLoader,
    },
    data() {
        return {
            LoginImg,
            Logo1,
            disabled: false,
            user_type: null,
            formData: {
                email: "",
                password: "",
            },
            user_id: null,
            challenge_metadata: null,
            currentStep: 1,
            showError: false,
            eye: require("@/assets/svg/eye.svg"),
            closedEye: require("@/assets/svg/closeeye.svg"),
            isPassVisible: false,
            isTempPassInvalid: false,
        };
    },
    methods: {
        async submitForm() {
            try {
                this.showError = true;
                this.disabled = true;
                if (this.currentStep == 1 && !this.isEmailValid) return (this.disabled = false);
                if (this.currentStep == 1) {
                    const email = this.formData.email;
                    const data = await setTempPass({email});
                    if (data.status == false) {
                        this.disabled = false;
                        this.$toast.error(data.message || "Something went wrong!");
                        return;
                    } else if (data.status == true) {
                        this.disabled = false;
                        this.$toast.success(data.message);
                        this.nextStep();
                        return;
                    } else {
                        this.disabled = false;
                        this.$toast.error(data.message);
                        return;
                    }
                }
                if (this.currentStep == 2 && this.isPassValid) {
                    const password = this.formData.password;
                    const payload = {
                        email: this.formData.email,
                        password: password,
                    };
                    const data = await verifyTempPass(payload);
                    this.disabled = false;
                    if (data.status == false) {
                        this.$toast.error(data.message || "Something went wrong!");
                        this.isTempPassInvalid = true;
                        return;
                    }
                    this.user_id = data.user_id;
                    this.user_type = data.user_type;
                    this.challenge_metadata = data.challenge_metadata;
                    this.isTempPassInvalid = false;
                    this.$toast.success("Temporary password verified successfully!");
                    this.moveToPath();
                }
                this.disabled = false;
            } catch (error) {
                this.disabled = false;
                this.isTempPassInvalid = true;
                this.$toast.error("Something went wrong!");
            }
        },
        togglePass() {
            this.$refs.passRef.type = this.$refs.passRef.type === "text" ? "password" : "text";
            this.isPassVisible = !this.isPassVisible;
        },
        handleInputBlur() {
            this.$refs.passRef.type = "password";
        },
        nextStep() {
            this.showError = false;
            this.currentStep++;
        },
        moveToPath() {
            // this.$router.push("update-password")
            const params = this.$route.query;
            params.challenge_metadata = this.challenge_metadata;
            params.user_id = this.user_id;
            params.user_type = this.user_type;
            this.$router.push({
                path: "update-password",
                query: params,
            });
        },
        isValidEmail(email) {
            const emailRegex = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i;
            return emailRegex.test(email);
        },
        handleChange() {
            this.isTempPassInvalid = false;
        },
    },
    computed: {
        isFormFilled() {
            return this.currentStep === 1 ? this.formData?.email : this.formData?.password;
        },
        isEmailValid() {
            return this.isValidEmail(this.formData.email);
        },
        activeUserEmail() {
            return sections.user_email;
        },
        activeUserName() {
            return sections.user_name;
        },
        isPassValid() {
            return this.formData?.password.length >= 8;
        },
    },
};
</script>
<style lang="scss">
.container {
    background-color: white;
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    height: 100vh;
    width: 100vw;

    .brand {
        height: 100%;
        background-color: var(--brand-color);
        display: grid;
        place-content: center;
        position: relative;

        .welcome {
            display: flex;
            flex-direction: column;
            align-items: center;
            position: relative;
            z-index: 999;

            #logo {
                width: 200px;
                height: 72px;
                margin-bottom: 5rem;
            }

            p {
                font-size: 32px;
                font-family: "Montserrat";
                color: white;
                margin: 0.5rem 2rem;
                font-weight: 200;
            }
        }

        #bg {
            position: absolute;
            height: 100%;
            width: 100%;
            background-repeat: no-repeat;
            background-size: cover;
        }
    }
    .loginImage-cnt {
        width: 50%;
        height: 100vh;
        // overflow: hidden;
        display: flex;
    }
    .form-cnt {
        display: flex;
        flex-direction: column;
        justify-content: center;
        padding: 6%;
        background-color: white;
    }
    form {
        max-width: 36rem;
    }
    .logo {
        width: 215px;
        height: 72px;
    }
    .btnDisable {
        background-color: #ddd;
        color: #858585;
    }
    .rounded {
        border-radius: 10px;
    }
    input {
        font-size: 16px;
        border-radius: 10px;
        border: 1px solid #d8dbe0;
    }
    input:focus {
        outline: 1px solid rgb(59, 130, 246);
    }
    input::placeholder {
        color: #49454f;
    }
    button {
        border-radius: 10px;
        border-width: 0px;
        font-size: 16px;
        font-weight: 700;
    }
    .auth {
        overflow-y: scroll;
    }
    * {
        font-family: "Poppins", sans-serif !important;
    }
    .hideError {
        visibility: hidden;
    }
    .error {
        position: absolute;
    }
    .errorColor {
        border: 1px solid red !important;
        outline: none !important;
    }
}
</style>
