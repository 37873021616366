<template>
    <div class="container">
        <section class="brand">
            <div class="welcome">
                <img class="logo" src="@/assets/logo.svg" alt="brand" />
                <p>Beyond the Records. Below the Surface Web.</p>
                <p>There is a lot to Discover.</p>
            </div>
            <div id="bg" :style="{backgroundImage: 'url(' + require('@/assets/auth-bg.svg') + ')'}"></div>
        </section>
        <section class="auth">
            <template>
                <div class="tw-w-full form-cnt tw-min-h-full">
                    <form @submit.prevent="() => submitForm(currentStep)" class="tw-pr-10">
                        <div class="tw-flex tw-flex-col tw-gap-8 tw-pr-0 tw-pl-8 tw-ml-4">
                            <div>
                                <!-- <Logo1/> -->
                                <img :src="Logo1" class="logo" />
                            </div>
                            <div v-if="currentStep === 1">
                                <p class="tw-block tw-mb-2 tw-text-black tw-text-2xl tw-font-semibold">Provide your name details below</p>
                            </div>
                            <div v-else>
                                <p class="tw-block tw-mb-2 tw-text-black tw-text-2xl tw-font-semibold">Login to your account</p>
                                <p class="tw-block tw-mb-2 tw-text-black tw-text-sm">Hi {{ personalData.username }}! Please enter a New Password</p>
                            </div>
                            <!-- step-1 form -->
                            <div v-if="currentStep === 1">
                                <div class="tw-my-8" v-for="(key, index) in requiredAttributes" :key="index">
                                    <label class="tw-block tw-mb-2 tw-text-sm tw-font-noraml tw-text-gray-900 tw-dark:text-white">{{ createFieldLabel(key) }}</label>
                                    <div class="tw-flex tw-items-center tw-relative">
                                        <input
                                            type="text"
                                            :placeholder="`Enter ${createFieldLabel(key)}`"
                                            class="tw-bg-gray-50 tw-border tw-border-gray-300 tw-text-gray-900 tw-text-base tw-rounded tw-focus:ring-blue-500 tw-focus:border-blue-500 tw-block tw-w-full tw-px-4 tw-py-2.5 tw-dark:bg-gray-700 tw-dark:border-gray-600 tw-dark:placeholder-gray-400 tw-dark:text-white tw-dark:focus:ring-blue-500 tw-dark:focus:border-blue-500 tw-h-12"
                                            @blur="handleInputBlur"
                                            v-model="formData.required_attributes[key]"
                                        />
                                    </div>
                                    <!-- <span v-if="showError && isPassValid" class="tw-text-red-600 tw-text-xs">required</span> -->
                                </div>
                            </div>
                            <!-- step-2 form -->
                            <div v-else>
                                <div class="tw-my-8 tw-relative">
                                    <label for="Temparary_password" class="tw-block tw-mb-2 tw-text-sm tw-font-noraml tw-text-gray-900 tw-dark:text-white">New Password</label>
                                    <div class="tw-flex tw-items-center tw-relative">
                                        <input
                                            type="password"
                                            ref="passRef"
                                            id="password"
                                            v-model="formData.password"
                                            autocomplete="new-password"
                                            class="tw-bg-gray-50 tw-border tw-border-gray-300 tw-text-gray-900 tw-text-base tw-rounded tw-focus:ring-blue-500 tw-focus:border-blue-500 tw-block tw-w-full tw-px-4 tw-py-2.5 tw-dark:bg-gray-700 tw-dark:border-gray-600 tw-dark:placeholder-gray-400 tw-dark:text-white tw-dark:focus:ring-blue-500 tw-dark:focus:border-blue-500 tw-h-12"
                                            :class="(passwordCheck && !isPassValidReqX) || (!isPassValidReqX && isPassFilled) ? 'errorColor' : 'tw-border-gray-300'"
                                            placeholder="Enter Password"
                                            @blur="handleInputBlur"
                                            @input="inputCheck"
                                        />
                                        <img :src="closedEye" class="tw-absolute tw-right-4 tw-cursor-pointer" @click="togglePass('passRef')" v-if="!isPassVisible" />
                                        <img :src="eye" class="tw-absolute tw-right-4 tw-cursor-pointer" @click="togglePass('passRef')" v-else />
                                    </div>
                                    <!-- <span :class="{'hideError':!(showError && !isPassValid && formData.password)}" class="tw-text-red-600 tw-text-xs error">Should be a minimum of 8 characters</span> -->
                                    <span :class="{hideError: !passwordCheck || (isPassValidReqX && isPassFilled)}" class="tw-text-red-600 tw-text-xs error">Password must contain atleast 8 characters, a lower case letter, an uppercase letter, a number and a special character.</span>
                                </div>
                                <div class="tw-my-8 tw-relative">
                                    <label for="Temparary_password" class="tw-block tw-mb-2 tw-text-sm tw-font-noraml tw-text-gray-900 tw-dark:text-white">Confirm Password</label>
                                    <div class="tw-flex tw-items-center tw-relative">
                                        <input
                                            type="password"
                                            ref="confPassRef"
                                            id="confPassword"
                                            v-model="formData.confPassword"
                                            autocomplete="new-password"
                                            class="tw-bg-gray-50 tw-border tw-text-gray-900 tw-text-base tw-rounded tw-focus:ring-blue-500 tw-focus:border-blue-500 tw-block tw-w-full tw-px-4 tw-py-2.5 tw-dark:bg-gray-700 tw-dark:border-gray-600 tw-dark:placeholder-gray-400 tw-dark:text-white tw-dark:focus:ring-blue-500 tw-dark:focus:border-blue-500 tw-h-12"
                                            :class="passConfCheck && !isConfPassValid ? 'errorColor' : 'tw-border-gray-300'"
                                            placeholder="Re Enter Password"
                                            @blur="handleInputBlur"
                                            @change="handleChange"
                                            @input="confPassInputCheck"
                                        />
                                        <img :src="closedEye" class="tw-absolute tw-right-4 tw-cursor-pointer" @click="togglePass('confPassRef')" v-if="!isconfPassVisible" />
                                        <img :src="eye" class="tw-absolute tw-right-4 tw-cursor-pointer" @click="togglePass('confPassRef')" v-else />
                                    </div>
                                    <!-- <span :class="{'hideError': !(showError && isPassValid && !isConfPassValid)}" class="tw-text-red-600 tw-text-xs error">Password doesn't match</span> -->
                                    <span :class="{hideError: !passConfCheck || (isConfPassValid && isConfPassFilled)}" class="tw-text-red-600 tw-text-xs error">Password and Confirm Password should match</span>
                                </div>
                            </div>
                            <div class="tw-flex">
                                <button v-if="currentStep == 2 && showBackButton" @click="prevStep" class="tw-px-2 backBtn tw-cursor-pointer">
                                    <img :src="backArrow" />
                                </button>
                                <button class="tw-bg-blue-500 tw-hover:bg-blue-700 tw-text-white tw-font-bold tw-py-2 tw-px-4 tw-rounded tw-focus:outline-none tw-focus:shadow-outline tw-w-full tw-h-12 tw-cursor-pointer" :class="{btnDisable: !isFormFilled || disabled}" type="submit" :disabled="!isFormFilled || disabled">
                                    <nLoader v-if="disabled" class="tw-h-8 tw-w-8"></nLoader>
                                    {{ !disabled ? "Continue" : "" }}
                                </button>
                            </div>
                        </div>
                    </form>
                </div>
            </template>
        </section>
    </div>
</template>
<script>
import {LoginImg, Logo1} from "@/plugins/common.js";
import {setNewPass} from "./services";
import NLoader from "@/components/n-loader";
import sections from "./store";

export default {
    components: {
        NLoader,
    },
    data() {
        return {
            LoginImg,
            Logo1,
            disabled: false,
            formData: {
                password: "",
                confPassword: "",
                required_attributes: {},
            },
            personalData: {
                username: "User",
            },
            currentStep: 1,
            showError: false,
            eye: require("@/assets/svg/eye.svg"),
            closedEye: require("@/assets/svg/closeeye.svg"),
            backArrow: require("@/assets/icons/backBtn.svg"),
            isPassVisible: false,
            isconfPassVisible: false,
            showBackButton: false,
            passwordCheck: false,
            passConfCheck: false,
        };
    },
    mounted() {
        if (sessionStorage.getItem("currentStep")) {
            this.currentStep = sessionStorage.getItem("currentStep");
        }
        if (sessionStorage.getItem("reqData")) {
            this.formData.required_attributes = JSON.parse(sessionStorage.getItem("reqData"));
        }
        if (this.requiredAttributes.length) {
            this.formData.required_attributes = this.createReqFieldsObject();
            this.showBackButton = true;
            this.currentStep = 1;
        } else {
            this.currentStep = 2;
        }
        if (this.getUserName) {
            this.personalData.username = this.getUserName;
        }
        // console.log("required_data", this.requiredAttributes());
    },
    methods: {
        inputCheck() {
            this.passwordCheck = true;
        },
        confPassInputCheck() {
            this.passConfCheck = true;
        },
        submitForm(step = 2) {
            this.nextStep();
            if (this.currentStep == 2) this.handleValidation(step);
        },
        togglePass(ref) {
            this.$refs[ref].type = this.$refs[ref].type === "text" ? "password" : "text";
            if (ref === "passRef") this.isPassVisible = !this.isPassVisible;
            else this.isconfPassVisible = !this.isconfPassVisible;
        },
        handleInputBlur() {
            // this.$refs.passRef.type = 'password';
        },
        moveToPath() {
            this.$router.push("/login");
        },
        async handleValidation(step) {
            try {
                this.disabled = true;
                if (step == 2 && this.isPassValidReqX && this.formData?.password === this.formData?.confPassword) {
                    const challenge_metadata = this.$route.query.challenge_metadata;
                    const user_id = this.$route.query.user_id;
                    const user_type = this.$route.query.user_type;
                    const required_attributes = this.formData?.required_attributes;
                    const payload = {
                        user_id: user_id,
                        user_type: user_type,
                        challenge_metadata: challenge_metadata,
                        password: this.formData.password,
                        required_attributes,
                    };
                    const data = await setNewPass(payload);
                    this.disabled = false;
                    if (data.status == false) {
                        if (data.status_code == 1005 || data.status_code == 1006) {
                            this.$toast.error(data.message);
                            this.$router.push("/");
                            return;
                        }
                        this.$toast.error(data.message || "Something went wrong!");
                        return;
                    }
                    this.$toast.success("Password updated. Please login. ");
                    sessionStorage.removeItem("currentStep");
                    sessionStorage.removeItem("reqData");
                    this.moveToPath();
                } else {
                    this.showError = true;
                    this.disabled = false;
                }
            } catch (error) {
                this.disabled = false;
                this.$toast.error("Something went wrong!");
            }
        },
        handleChange() {
            // this.showError = false;
            this.disabled = false;
        },
        nextStep() {
            this.showError = false;
            if (this.currentStep < 2) {
                this.currentStep++;
                sessionStorage.setItem("currentStep", this.currentStep);
                sessionStorage.setItem("reqData", JSON.stringify(this.formData.required_attributes));
            }
        },
        prevStep() {
            if (this.currentStep > 1) {
                this.currentStep--;
                sessionStorage.setItem("currentStep", this.currentStep);
            }
        },
        createReqFieldsObject() {
            const myObject = Object.fromEntries(this.requiredAttributes?.map((key) => [key, ""]));
            return myObject;
        },
        createFieldLabel(value) {
            return value.charAt(0).toUpperCase() + value.slice(1).replace(/_/g, " ");
        },
    },
    computed: {
        isFormFilled() {
            if (this.currentStep == 1) {
                return Object.values(this.formData?.required_attributes).every((val) => val.trim() != "");
            } else {
                return this.formData?.password && this.formData?.confPassword;
            }
        },
        isPassFilled() {
            return this.formData?.password.length;
        },
        isConfPassFilled() {
            return this.formData?.confPassword.length;
        },
        isPassValidReqX() {
            const regexPattern = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&#])[A-Za-z\d@$!%*?&#]{8,}$/;
            if (this.formData?.password.length == 0) {
                this.passwordCheck = false;
            }
            // Perform validation on the inputValue using the regex pattern
            return regexPattern.test(this?.formData?.password);
        },
        requiredAttributes() {
            return sections.required_attributes;
        },
        getUserName() {
            return sections.user_name;
        },
        isConfPassValid() {
            if (this.formData?.confPassword.length == 0) {
                this.passConfCheck = false;
            }
            return this.formData?.password === this.formData?.confPassword;
        },
    },
};
</script>
<style lang="scss">
.container {
    background-color: white;
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    height: 100vh;
    width: 100vw;

    .brand {
        height: 100%;
        background-color: var(--brand-color);
        display: grid;
        place-content: center;
        position: relative;

        .welcome {
            display: flex;
            flex-direction: column;
            align-items: center;
            position: relative;
            z-index: 999;

            #logo {
                width: 200px;
                height: 72px;
                margin-bottom: 5rem;
            }

            p {
                font-size: 32px;
                font-family: "Montserrat";
                color: white;
                margin: 0.5rem 2rem;
                font-weight: 200;
            }
        }

        #bg {
            position: absolute;
            height: 100%;
            width: 100%;
            background-repeat: no-repeat;
            background-size: cover;
        }
    }

    .loginImage-cnt {
        width: 50%;
        height: 100vh;
        // overflow: hidden;
        display: flex;
    }
    .form-cnt {
        display: flex;
        flex-direction: column;
        justify-content: center;
        padding: 6%;
        background-color: white;
    }
    form {
        max-width: 36rem;
    }
    .logo {
        width: 215px;
        height: 72px;
    }
    .btnDisable {
        background-color: #ddd;
        color: #858585;
    }
    .rounded {
        border-radius: 10px;
    }
    input {
        font-size: 16px;
        border-radius: 10px;
        border: 1px solid #d8dbe0;
    }
    input:focus {
        outline: 1px solid rgb(59, 130, 246);
    }
    input::placeholder {
        color: #49454f;
    }
    button {
        border-radius: 10px;
        border-width: 0px;
        font-size: 16px;
        font-weight: 700;
    }
    .backBtn {
        width: 65px;
        margin-right: 10px;
        display: flex;
        align-items: center;
        justify-content: center;
        background: rgb(251, 251, 251);
        border-radius: 10px;
        border: 1px solid #d8dbe0;
    }
    .auth {
        overflow-y: scroll;
    }
    * {
        font-family: "Poppins", sans-serif !important;
    }
    .hideError {
        visibility: hidden;
    }
    .error {
        position: absolute;
    }
    .errorColor {
        border: 1px solid red !important;
        outline: none !important;
    }
}
</style>
